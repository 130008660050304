import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'start',
    loadChildren: () => import('./start/start.module').then( m => m.StartPageModule)
  },
  {
    path: 'information/:id',
    loadChildren: () => import('./information/information.module').then( m => m.InformationPageModule)
  },
  {
    path: 'tours-local',
    loadChildren: () => import('./tours-local/tours-local.module').then( m => m.ToursLocalPageModule)
  },
  {
    path: 'tours-home',
    loadChildren: () => import('./tours-home/tours-home.module').then( m => m.ToursHomePageModule)
  },
  {
    path: 'free-tour',
    loadChildren: () => import('./free-tour/free-tour.module').then( m => m.FreeTourPageModule)
  },
  {
    path: 'model-villa',
    loadChildren: () => import('./model-villa/model-villa.module').then( m => m.ModelVillaPageModule)
  },
  {
    path: 'tour-start',
    loadChildren: () => import('./tour-start/tour-start.module').then( m => m.TourStartPageModule)
  },
  {
    path: 'tour-location/:file',
    loadChildren: () => import('./tour-location/tour-location.module').then( m => m.TourLocationPageModule)
  },
  {
    path: 'tour-directions',
    loadChildren: () => import('./tour-directions/tour-directions.module').then( m => m.TourDirectionsPageModule)
  },
  {
    path: 'qr-scanner/:tour',
    loadChildren: () => import('./qr-scanner/qr-scanner.module').then( m => m.QrScannerPageModule)
  },
  {
    path: 'tour-end',
    loadChildren: () => import('./tour-end/tour-end.module').then( m => m.TourEndPageModule)
  },
  {
    path: 'tour-skip',
    loadChildren: () => import('./tour-skip/tour-skip.module').then( m => m.TourSkipPageModule)
  },
  {
    path: 'couchmode',
    loadChildren: () => import('./couchmode/couchmode.module').then( m => m.CouchmodePageModule)
  },
  {
    path: 'tour-location-home/:file',
    loadChildren: () => import('./tour-location-home/tour-location-home.module').then( m => m.TourLocationHomePageModule)
  },
  {
    path: 'tour-local-intro/:file',
    loadChildren: () => import('./tour-local-intro/tour-local-intro.module').then( m => m.TourLocalIntroPageModule)
  },
  {
    path: 'tour-local-list/:file',
    loadChildren: () => import('./tour-local-list/tour-local-list.module').then( m => m.TourLocalListPageModule)
  },
  {
    path: 'tour-local-navigation/:file',
    loadChildren: () => import('./tour-local-navigation/tour-local-navigation.module').then( m => m.TourLocalNavigationPageModule)
  },
  {
    path: 'content-ar/:file/:index',
    loadChildren: () => import('./content-ar/content-ar.module').then( m => m.ContentArPageModule)
  },
  {
    path: 'content-slider/:file/:index',
    loadChildren: () => import('./content-slider/content-slider.module').then( m => m.ContentSliderPageModule)
  },
  {
    path: 'content-video/:file/:index',
    loadChildren: () => import('./content-video/content-video.module').then( m => m.ContentVideoPageModule)
  },
  {
    path: 'content-zoom/:file/:index',
    loadChildren: () => import('./content-zoom/content-zoom.module').then( m => m.ContentZoomPageModule)
  },
  {
    path: 'content-model/:file/:index',
    loadChildren: () => import('./content-model/content-model.module').then( m => m.ContentModelPageModule)
  },
  {
    path: 'content-pano/:file/:index',
    loadChildren: () => import('./content-pano/content-pano.module').then( m => m.ContentPanoPageModule)
  },
  {
    path: 'content-quiz/:file/:index',
    loadChildren: () => import('./content-quiz/content-quiz.module').then( m => m.ContentQuizPageModule)
  },
  {
    path: 'content-diagram/:file/:index',
    loadChildren: () => import('./content-diagram/content-diagram.module').then( m => m.ContentDiagramPageModule)
  },
  {
    path: 'tour-location-home-start/:file',
    loadChildren: () => import('./tour-location-home-start/tour-location-home-start.module').then( m => m.TourLocationHomeStartPageModule)
  },
  {
    path: 'tour-children-location/:file',
    loadChildren: () => import('./tour-children-location/tour-children-location.module').then( m => m.TourChildrenLocationPageModule)
  },
  {
    path: 'tour-children-navigation/:file',
    loadChildren: () => import('./tour-children-navigation/tour-children-navigation.module').then( m => m.TourChildrenNavigationPageModule)
  },
  {
    path: 'game/:id',
    loadChildren: () => import('./game/game.module').then( m => m.GamePageModule)
  },
  {
    path: 'content-modal',
    loadChildren: () => import('./content-modal/content-modal.module').then( m => m.ContentModalPageModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./map/map.module').then( m => m.MapPageModule)
  },
  {
    path: 'debug',
    loadChildren: () => import('./debug/debug.module').then( m => m.DebugPageModule)
  },
  {
    path: 'content-animation/:file/:index',
    loadChildren: () => import('./content-animation/content-animation.module').then( m => m.ContentAnimationPageModule)
  },
  {
    path: 'imprint',
    loadChildren: () => import('./imprint/imprint.module').then( m => m.ImprintPageModule)
  },
  {
    path: 'soundwalks',
    loadChildren: () => import('./soundwalks/soundwalks.module').then( m => m.SoundwalksPageModule)
  },
  {
    path: 'soundwalks-navigation',
    loadChildren: () => import('./soundwalks-navigation/soundwalks-navigation.module').then( m => m.SoundwalksNavigationPageModule)
  },
  {
    path: 'soundwalks-introduction',
    loadChildren: () => import('./soundwalks-introduction/soundwalks-introduction.module').then( m => m.SoundwalksIntroductionPageModule)
  },
  {
    path: 'soundwalks-overview',
    loadChildren: () => import('./soundwalks-overview/soundwalks-overview.module').then( m => m.SoundwalksOverviewPageModule)
  },
  {
    path: 'soundwalks-video',
    loadChildren: () => import('./soundwalks-video/soundwalks-video.module').then( m => m.SoundwalksVideoPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
